import {combineReducers} from "redux"
import syncEventsReducer from "./sync-events/reducer"
import syncResourcesReducer from "./sync-resources/reducer"
import {userReducer} from "./userReducer"
import {asanaSyncInfoReducer} from "./sync-asana-status/reducer";
import {websocketReducer} from "./websocket/websocketReducer";
import {configReducer} from "./calendar-config/reducer";
import {labelReducer} from "./labels-config/reducer";
import {categoriesReducer} from "./categories/reducer";
import {mephistoReducer} from "./mephisto/reducer";

export const rootReducer = combineReducers({
    user: userReducer,
    webSocketClient: websocketReducer,
    calendarConfig: configReducer,
    calendarCategories: categoriesReducer,
    labelsConfig: labelReducer,
    mephistoConfig: mephistoReducer,
    syncEvents: syncEventsReducer,
    syncResources: syncResourcesReducer,
    asanaSyncInfo: asanaSyncInfoReducer,
})
