import {decodeResourceId} from "./calendars/bryntum_configs/compositeEventUtils";

export const MEPHISTO_URL = 'https://mephisto-cross.stage.belka-games.com/'
//prod
//export const MEPHISTO_API_URL = 'https://cross-campaign-prod.stage.belka-games.com/'
export const MEPHISTO_API_URL = 'https://cross-campaign-prod.stage.belka-games.com/'

export const EVENT_TYPE = {
    HOLIDAY: "holiday",
    IMPORTANT_DATES: "importantDate",
    EVENT: "event",
    AB_TEST: "ab-test",
    AB_TEST_COMMENT: "ab-test-comment",
    COMMUNITY: "community",
    RELEASE: "release",
    ASO: "aso",
    OFFER: "offer",
    PROMO: "promo",
    PUSH: "push",
    FEATURING: "featuring",
    BRANDFORMANCE: "brandformance",
    MATCH3: "match3",
    JAPANESE_HOLIDAYS: "japaneseHolidays"
}
export const SELECTED_GAME = {
    NO_GAME: "NO_GAME",
    CM: "CM",
    SC: "SC",
    BA: "BA",
    RT: "RT",
    TOH: "TOH",
}

export const MONGO_STATUS = {
    NOT_MODIFIED: "NOT_MODIFIED",
    REMOVED: "REMOVED",
    UPDATED: "UPDATED",
    CREATED: "CREATED",
}
export const SYNC_ASANA_STATUS = {
    SYNCED: "SYNCED",
    CHANGED: "CHANGED",
    IN_SYNC: "IN_SYNC",
    DUMP_EVENTS: "DUMP_EVENTS"
}

export const COMPLETED_SYNC_STATUS = {
    COMPLETED: "COMPLETED",
    FAILED: "FAILED",
    IN_PROGRESS: "IN_PROGRESS"
}
export const USER_ROLE = {
    EDITOR: "editor",
    VIEWER: "viewer",
}
export const COMMUNITY_TEMPLATES = {
    "1161838019957436": 'Новость 1 (все новое)',
    "1161838019957434": 'Новость 2 (реюз текста + иконка)',
    "1161838019957498": 'Новость 3 (реюз текста, новая иконка)',
    "1199688064422978": 'Опрос на бонус',
    "1188851044426938": 'Бонус - dd.mm.yy',
    "1161838019957470": 'Add me',
    "1188851044426905": 'Facebook Community'};


export const RESOURCES = {
    MATCH3: 'Match 3'
};
export const HARD_SOFT_UPDATE = {
    RESOURCE_NAME: "Hard/Soft Update",
    ASANA_SECTION_NAME: "Запуск"
}

export const BA_EVENTS_EXPEDITIONS = "expeditions";
export const BA_EVENTS_UD = "ud";
export const BA_EVENTS_MOSAIC = "mosaic";
export const BA_EVENTS_REGATTA = "regatta";
export const BA_EVENTS_DICE = "dice";
export const BA_EVENTS_MERGE = "merge";

export const getEventSubType = (event, templates) => {
    if (event.type !==  EVENT_TYPE.EVENT) {
        return "";
    }
    const name = event.name.toLowerCase().trim();
    const resource = decodeResourceId(event.resourceId);
    const templateName = (event.template !== undefined && event.template !== null  && templates[resource] !== undefined && templates[resource][event.template] ? templates[resource][event.template] : "").toLowerCase();
    const resourceName = resource.trim().toLowerCase();
    if (name.includes(":mso") ||
        name.includes(":х3 offer") ||
        name.includes(":regatta offer") ||
        name.includes(":dice offer")) {
        return "";
    }
    if (name.includes(BA_EVENTS_EXPEDITIONS) || templateName.includes(BA_EVENTS_EXPEDITIONS) || resourceName.includes(BA_EVENTS_EXPEDITIONS)) {
        return BA_EVENTS_EXPEDITIONS;
    } else  if (name.includes(BA_EVENTS_UD) || templateName.includes(BA_EVENTS_UD) || resourceName.includes(BA_EVENTS_UD)) {
        return BA_EVENTS_UD;
    } else  if (name.includes(BA_EVENTS_MOSAIC) || templateName.includes(BA_EVENTS_MOSAIC) || resourceName.includes(BA_EVENTS_MOSAIC)) {
        return BA_EVENTS_MOSAIC;
    } else  if (name.includes(BA_EVENTS_REGATTA) || templateName.includes(BA_EVENTS_REGATTA) || resourceName.includes(BA_EVENTS_REGATTA)) {
        return BA_EVENTS_REGATTA;
    } else  if (name.includes(BA_EVENTS_DICE) || templateName.includes(BA_EVENTS_DICE) || resourceName.includes(BA_EVENTS_DICE)) {
        return BA_EVENTS_DICE;
    } else  if (name.includes(BA_EVENTS_MERGE) || templateName.includes(BA_EVENTS_MERGE) || resourceName.includes(BA_EVENTS_MERGE)) {
        return BA_EVENTS_MERGE;
    } else  {
        return "";
    }
}

export const BA_EVENTS_OFFERS_NAME = {
    "expeditions": [{
        name: "MSO UR",
        durationDays: null,
        isInTheEndOfMainEvent: false
    }],
    "ud": [{
        name: "MSO UD",
        durationDays: 2,
        isInTheEndOfMainEvent: true
    }],
    "mosaic": [
        {
            name:"MSO Mosaic",
            durationDays: 2,
            isInTheEndOfMainEvent: true
        },
        {
            name: "х3 Offer",
            durationDays: 4,
            isInTheEndOfMainEvent: false
        }
        ],
    "regatta": [{
        name: "Regatta Offer",
        durationDays: null,
        isInTheEndOfMainEvent: false
    }],

    "dice": [{
        name: "Dice Offer",
        durationDays: null,
        isInTheEndOfMainEvent: false
    }],
    "merge": [{
        name: "MSO Merge",
        durationDays: null,
        isInTheEndOfMainEvent: false
    }]
}
