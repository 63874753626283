import store from "./store"

import {EVENT_TYPE, MEPHISTO_API_URL, MEPHISTO_URL, SELECTED_GAME} from "./constants";

import {SYNC_INFO, SYNC_PROGRESS} from "./redux/sync-asana-status/actions";
import {updateSyncFromAsanaStatus} from "./calendars/bryntum_helpers";

export const sendAddEvent = async (event, game) => {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(event)
    }

    fetch(`/${game}`, options)
}

export const sendUpdateEvent = async (event, game) => {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(event)
    }
    fetch(`/${game}`, options).then((response) => {
        if (response.status === 202) {
            if (event.parentRelease === undefined) {
            }
        }
    })
}

export const sendRemoveEvent = async (event, game, isHardDelete = false) => {
    let params = {
        type: event.type,
    }
    if (game === SELECTED_GAME.CM.toLowerCase()
        || game === SELECTED_GAME.RT.toLowerCase()
        || game === SELECTED_GAME.TOH.toLowerCase()
    ) {
        params.isHardDelete = isHardDelete
    }

    const url = `/${game}/${event.id}?${new URLSearchParams(params).toString()}`

    const options = {
        method: "DELETE"
    }

    return fetch(url, options).then((response) => {
        if (response.status === 202 && event.type !== EVENT_TYPE.PROMO) {
        }
    })
}
export const sendRemoveReleaseEvent = async (id, type, game) => {
    const params = {
        type: type,
    }

    const url = `/${game}/${id}?${new URLSearchParams(params).toString()}`

    const options = {
        method: "DELETE"
    }

    fetch(url, options).then((response) => {

    })
}

export const getAsanaSyncTasksInfo = (game, scheduler) => {

    const url = `/${game}/asana-sync-tasks-info`

    const options = {
        method: "GET"
    }

    return fetch(url, options)
        .then((response) => response.json()
            .then((result) => {
                store.dispatch({type: SYNC_INFO, payload: result})
                updateSyncFromAsanaStatus(scheduler, result);
            }))

}

export const getMephistoEvents = () => {

    const url = MEPHISTO_API_URL + `campaign/api/admin/publisher/context?environment=production&from=1735686000&till=1798758000`

    const options = {
        method: "GET"
    }

    return fetch(url, options).then((response) => response.json())
        .catch((error) => {

        });

}

export const getAsanaSyncTasksProgress = (game) => {

    const url = `/${game}/asana-sync-tasks-progress`

    const options = {
        method: "GET"
    }

    return fetch(url, options)
        .then((response) => response.json()
            .then((result) => store.dispatch({type: SYNC_PROGRESS, payload: result})))

}
export const pushEventChangesInAsana = (eventRecord, game) => {
    console.log("pushEventChangesInAsana")

    const params = {
        eventId: eventRecord.id,
        type: eventRecord.type
    }
    const options = {
        method: "POST"
    }
    const url = `/${game}/sync-event?${new URLSearchParams(params).toString()}`

    return fetch(url, options).then((response) => {
    })
}

export const pushBulkEventsToAsana = (eventRecords, game) => {

    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body:  JSON.stringify({
            eventIds: eventRecords.map(e => e.id)
        })
    }
    const url = `/${game.toLowerCase()}/sync-batch-events/${eventRecords[0].data.type}`

    fetch(url, options).then((response) => {
    })
}

export const getAsanaProjectStatusByVersion = (game, version) => {

    return fetch(
        `/asana/project-status?game=${game}&version=${version}`,
        {
            method: "GET",
        }
    );
}
export const editEventNote = (game, event) => {

    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(event)
    }
    fetch(`${game.toLowerCase()}/editing-note`, options
    )
        .then((response) => response.json())
        .catch((error) => {

        });
}

export const cloneCategory = (categoryRequest, game, requestLoading) => {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(categoryRequest)
    }
    fetch(`clone-category/`+game, options)
        .then((response) => requestLoading = false)
        .catch((error) => {
            requestLoading = false;
        });
}


export const getEventLogs = (game) => {
    return fetch(
        `/event-logs?game=${game.toLowerCase()}`,
        {
            method: "GET",
        }
    ).then((response) => response.json())
        .catch((error) => {

        });
}