import {FETCH_LABELS_CONFIG, FETCH_MEPHISTO} from "../types";


const initialStateTimeConfig = {campaigns: []}
export const mephistoReducer = (state = initialStateTimeConfig, action) => {
    switch (action.type) {
        case FETCH_MEPHISTO:
            return  action.payload;
        default:
            return state
    }
};